// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

:root {
  --mdc-theme-primary: var(--linq-color-primary-500);
  --mdc-theme-secondary: var(--linq-color-secondary-500);
  --mdc-ripple-color: var(--linq-color-primary-300);
  --mdc-typography-font-family: var(--linq-font-primary);
}

$linq-app-primary-palette: (
  100: var(--linq-color-primary-300),
  500: var(--linq-color-primary-500),
  700: var(--linq-color-primary-700),
  contrast: (
    100: var(--linq-color-primary-contrast-300),
    500: var(--linq-color-primary-contrast-500),
    700: var(--linq-color-primary-contrast-700),
  ),
);

$linq-app-secondary-palette: (
  100: var(--linq-color-secondary-300),
  500: var(--linq-color-secondary-500),
  700: var(--linq-color-secondary-700),
  contrast: (
    100: var(--linq-color-secondary-contrast-300),
    500: var(--linq-color-secondary-contrast-500),
    700: var(--linq-color-secondary-contrast-700),
  ),
);

$linq-app-warn-palette: (
  100: var(--linq-color-alert-100),
  500: var(--linq-color-alert-200),
  700: var(--linq-color-alert-300),
  contrast: (
    100: var(--linq-color-alert-contrast-100),
    500: var(--linq-color-alert-contrast-200),
    700: var(--linq-color-alert-contrast-300),
  ),
);

$merchants-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($linq-app-primary-palette),
      accent: mat.m2-define-palette($linq-app-secondary-palette),
      warn: mat.m2-define-palette($linq-app-warn-palette),
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($merchants-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($merchants-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($merchants-theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-expansion-panel-header-title {
  color: var(--linq-color-primary-400) !important;
  flex-basis: unset;
}

.mat-expansion-panel-header-description {
  font-weight: normal;
  font-size: small;
  flex-grow: 5 !important;
  align-self: flex-start;
}

mat-expansion-panel-header[aria-disabled='true'] {
  .mat-expansion-panel-header-title {
    color: inherit !important;
  }
}

.container {
  background-color: var(--linq-color-gray-200);
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  background-color: var(--linq-color-gray-200) !important;
}

a[href]:not(.mat-menu-item):not([tabindex='-1']):not(:hover):focus,
area[href]:not([tabindex='-1']):not(:hover):focus,
input:not([disabled]):not(.mat-input-element):not([tabindex='-1']):not(:hover):focus,
select:not([disabled]):not([tabindex='-1']):not(:hover):focus,
textarea:not([disabled]):not(.mat-input-element):not([tabindex='-1']):not(:hover):focus,
button:not([disabled]):not(.mat-button-toggle-button):not(.mat-menu-item):not([tabindex='-1']):not(:hover):focus,
iframe:not([tabindex='-1']):not(:hover):focus,
[tabindex]:not(.mat-select):not(.mat-input-element):not(.mat-button-toggle-button):not(.mdc-tab):not(.mat-mdc-tab):not(
    .mat-menu-item
  ):not([tabindex='-1']):not(:hover):focus,
[contentEditable='true']:not([tabindex='-1']):not(:hover):focus {
  outline: unset;
}

.linq-stepper {
  .mat-step-icon.mat-step-icon-state-error {
    color: var(--linq-color-alert-100);
    background-color: unset;
  }
}

.mat-mdc-form-field-type-checkbox,
.mat-mdc-form-field-type-radio {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-width: 0px !important;
  }
  .mat-mdc-text-field-wrapper {
    padding: 0;
    background-color: transparent;
    .mat-mdc-form-field-focus-overlay {
      display: none;
    }
    .mdc-line-ripple {
      display: none;
    }
    .mat-mdc-form-field-infix {
      padding: 0 !important;
      min-height: unset;
    }
  }
}

.no-border {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-width: 0px !important;
  }
}

.vertical-radio-group {
  .mat-mdc-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
